<template>
    <div class="about-us-all">
		<img src="@/assets/images/aboutUs/office-location.png" alt="" class="about-us-img"/>
		<div class="about-us-center">
			Shield Medical Solutions is the worlds largest PPE facemask manufacturing solution provider.  Established in 2015 from Sydney Australia with over 100 staff around the world providing machinery and materials plus technical support to PPE manufacturers. We have great testimonials from PPE manufacturers in 21 countries including USA, Columbia, Italy, Hungary, Portugal, Czech Republic, Japan, Israel, China, Singapore, Malaysia, Thailand, India, Australia and New Zealand. 
		</div>
		<div class="about-us-center title-weight">
            If you are thinking about setting up a Mask business in your country or have an existing PPE business that needs upgrading. Shield medicals we are your number 1 choice.
		</div>
		<div class="about-us-center">
			The Shield Medical Alliance of manufacturers around the world work closely together as a buyers group combining orders to purchase best possible raw materials at the lowest prices.
		</div>
		<div class="about-us-center">
			To support manufacturers we also have well developed sales networks bring orders to our member manufacturers. Our ability to develop new products to match the end buyer’s exact needs and price level has helped us to secured many large orders for our member manufacturers.
		</div>
		<div class="about-us-center title-bold-2">
			At Shield Medicals we take pride in our ability to help our members manufacturers establish themselves as a long term PPE provider in its local country.
		</div>
		<img src="@/assets/images/aboutUs/office-location-image.png" alt="" class="about-us-img-2"/>
		<img src="@/assets/images/home/under.jpg" alt="" class="about-us-img" v-if="1==2"/>
        <div class="about-us-center title-next" v-if="1==2">We also custom develop mask for our customers</div>
		<div class="about-us-center titile-under" v-if="1==2">(like this professional anti-fog, anti-reflective 4 layers mask for all the hospitals in Australia)</div>
		<img src="@/assets/images/aboutUs/about2.png" v-if="1==2" alt="" class="about-us-img"/>
		<div class="about-us-center" v-if="1==2">Combining our leading innovative development capabilities with our strong focus on product quality, our mission is to meet the local and global demand for high quality PPE with products that our customers love and trust.</div>
	</div>
</template>

<script>
	import '@/assets/less/AboutUs.less';
	export default {
		name: 'AboutUs',
		components: {},
		data() {
			return {}
		}
	}
</script>